import { FC, FormEvent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Container, Grid, TextField } from "@mui/material";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { ResetPasswordWithToken } from "../../types/user";
import { resetPasswordWithTokenThunk } from "../../store/thunks/userThunks";

export const ResetPasswordPage: FC = () => {
	const { token } = useParams();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [formData, SetFormData] = useState<ResetPasswordWithToken>({
		Token: token,
		Password: "",
	});

	const HandleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		dispatch(resetPasswordWithTokenThunk(formData));
		SetFormData({ Token: "empty", Password: "" });
		setTimeout(() => {
			navigate("/");
		}, 1000);
	};

	const HandleCancel = () => {
		navigate(-1);
	};

	return (
		<Grid
			container
			spacing={0}
			direction="column"
			alignItems="center"
			justifyContent="center"
			sx={{
				minHeight: "100vh",
				backgroundImage:
					"url(https://images.unsplash.com/photo-1573561507036-b0110570e2e3?q=80&w=1587&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",

				backgroundSize: "cover",
			}}
		>
			<Grid item xs={3} padding={3}>
				<Container
					component="main"
					sx={{
						backgroundColor: "white",
						borderRadius: "15px",
						paddingY: "10px",
					}}
				>
					<Box component="form" autoComplete="off" onSubmit={HandleSubmit}>
						<Grid direction="column" spacing={2}>
							<TextField
								type="password"
								label="New Password"
								value={formData.Password}
								sx={{
									margin: "1rem",
								}}
								onChange={e => {
									SetFormData({ ...formData, Password: e.target.value });
								}}
							/>
							<Grid item>
								<Button
									sx={{
										color: "#232323",
										bgcolor: "#ffffff",
										":hover": { bgcolor: "#ffcccb" },
										margin: 1,
									}}
									variant="contained"
									onClick={HandleCancel}
								>
									Cancel
								</Button>
							</Grid>
							<Grid item>
								<Button
									sx={{
										color: "#ffffff",
										bgcolor: "#232323",
										":hover": { bgcolor: "#ffccbb" },
										margin: 1,
									}}
									variant="contained"
									type="submit"
								>
									Confirm New Password
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Grid>
		</Grid>
	);
};
