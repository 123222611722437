import {
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReadUser } from "../../types/user";
import { GridColumn } from "../../types/DataGridTypes";
import { EditValueDialogue } from "../EditValueDialogue/EditValueDialogue";

interface Props {
	columns: GridColumn[];
	users: ReadUser[];
}

export const UsersTable: FC<Props> = ({ columns, users }) => {
	const navigate = useNavigate();
	const [selectedValue, setSelectedValue] = useState<string>("");
	const [open, setOpen] = useState(false);

	const handleValueClick = (value: string) => {
		setSelectedValue(value);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<TableContainer
				component={Paper}
				sx={{
					marginTop: "20px",
				}}
			>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead
						sx={{
							backgroundColor: "#f5f5f5",
							"& .MuiTableCell-root": {
								fontWeight: "bold",
							},
						}}
					>
						<TableRow>
							{columns.map(col => (
								<TableCell key={col.field}>{col.headerName}</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{users.map(row => (
							<TableRow key={row.id}>
								<TableCell
									sx={{
										":hover": {
											backgroundColor: "#f0f0f0",
											cursor: "pointer",
										},
									}}
									onClick={() => handleValueClick(row.firstName)}
									align="left"
									component="th"
									scope="row"
								>
									{row.firstName}
								</TableCell>
								<TableCell
									sx={{
										":hover": {
											backgroundColor: "#f0f0f0",
											cursor: "pointer",
										},
									}}
									onClick={() => handleValueClick(row.lastName)}
									align="left"
								>
									{row.lastName}
								</TableCell>
								<TableCell
									sx={{
										":hover": {
											backgroundColor: "#f0f0f0",
											cursor: "pointer",
										},
									}}
									onClick={() => handleValueClick(row.email)}
									align="left"
								>
									{row.email}
								</TableCell>
								<TableCell
									sx={{
										":hover": {
											backgroundColor: "#f0f0f0",
											cursor: "pointer",
										},
									}}
									onClick={() => handleValueClick(row.name)}
									align="left"
								>
									{row.name}
								</TableCell>
								<TableCell align="left">{row.roles.join(", ")}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<EditValueDialogue
				open={open}
				onClose={handleClose}
				valueToEdit={selectedValue}
			/>
		</div>
	);
};
