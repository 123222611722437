import { SerializedError, createSlice } from "@reduxjs/toolkit";
import { refreshTokenResponse } from "../../../types/token";
import { refreshTokenThunk } from "../../thunks/userThunks";

interface RefreshState {
	refreshTokenResponse: refreshTokenResponse;
	loading: string;
	error: SerializedError | null;
}

const initialState: RefreshState = {
	loading: "idle",
	refreshTokenResponse: {
		tokenData: {
			accessToken: "",
			refreshToken: "",
		},
	},
	error: null,
};

const refreshTokenSlice = createSlice({
	name: "refreshToken",
	initialState,
	reducers: {
		resetRefreshState: state => {
			state.loading = initialState.loading;
			state.refreshTokenResponse = initialState.refreshTokenResponse;
			state.error = initialState.error;
		},
	},
	extraReducers: builder => {
		builder.addCase(refreshTokenThunk.fulfilled, (state, action) => {
			state.refreshTokenResponse = action.payload;
			state.loading = "loaded";
		});
		builder.addCase(refreshTokenThunk.pending, state => {
			state.loading = "loading";
		});
		builder.addCase(refreshTokenThunk.rejected, (state, action) => {
			state.error = action.error;
			state.loading = "error";
		});
	},
});

export const { resetRefreshState } = refreshTokenSlice.actions;

export default refreshTokenSlice.reducer;
