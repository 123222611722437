import { SerializedError, createSlice } from "@reduxjs/toolkit";
import { registerUserThunk } from "../../thunks/userThunks";

interface registerUserSlice {
	loading: string;
	error: SerializedError | null;
}

const initState: registerUserSlice = {
	loading: "idle",
	error: null,
};

const registerUserSlice = createSlice({
	name: "registerUser",
	initialState: initState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(registerUserThunk.fulfilled, state => {
			state.loading = "loaded";
		});
		builder.addCase(registerUserThunk.pending, state => {
			state.loading = "loading";
		});
		builder.addCase(registerUserThunk.rejected, (state, action) => {
			state.error = action.error;
			state.loading = "error";
		});
	},
});

export default registerUserSlice.reducer;
