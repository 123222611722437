import { SerializedError, createSlice } from "@reduxjs/toolkit";
import { GetAllUsersResponse } from "../../../types/user";
import { getAllUsersThunk } from "../../thunks/userThunks";

interface AllUsersState {
	allUsers: GetAllUsersResponse;
	loading: string;
	error: SerializedError | null;
}

const initState: AllUsersState = {
	loading: "idle",
	allUsers: {} as GetAllUsersResponse,
	error: null,
};

const getAllUsersSlice = createSlice({
	name: "getAllUsers",
	initialState: initState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getAllUsersThunk.fulfilled, (state, action) => {
			state.allUsers = action.payload;
			state.loading = "loaded";
		});
		builder.addCase(getAllUsersThunk.pending, state => {
			state.loading = "loading";
		});
		builder.addCase(getAllUsersThunk.rejected, (state, action) => {
			state.error = action.error;
			state.loading = "error";
		});
	},
});

export default getAllUsersSlice.reducer;
