import {
	SerializedError,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import { DndApi } from "../../services/dndutilsService";
import { GetAllNamesResponse } from "../../types/names";

interface AllNamesState {
	allNames: GetAllNamesResponse;
	loading: string;
	error: SerializedError | null;
}

const initState: AllNamesState = {
	loading: "idle",
	allNames: {} as GetAllNamesResponse,
	error: null,
};

const getAllNamesSlice = createSlice({
	name: "getAllNames",
	initialState: initState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getAllNamesThunk.fulfilled, (state, action) => {
			state.allNames = action.payload;
			state.loading = "loaded";
		});
		builder.addCase(getAllNamesThunk.pending, state => {
			state.loading = "loading";
		});
		builder.addCase(getAllNamesThunk.rejected, (state, action) => {
			state.error = action.error;
			state.loading = "error";
		});
	},
});

export const getAllNamesThunk = createAsyncThunk(
	"getAllNamesSlice/getAllNames",
	async () => {
		const response = await DndApi.getAllNames();
		const data = await response.json();

		if (!response.ok) {
			throw new Error(data.error);
		}

		return data;
	}
);

export default getAllNamesSlice.reducer;
