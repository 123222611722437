import { Box, Typography } from "@mui/material";
import { MainLayout } from "./MainLayout";
import { GetNames } from "../GetNames/GetNames";

export const DndUtils = () => {
	const handleClick = () => {
		window.location.href = "https://www.github.com/alexgoold";
	};
	return (
		<MainLayout>
			<Box
				sx={{
					display: "flex",
					justifyContent: "top",
					alignItems: "center",
					flexDirection: "column",
					minHeight: "100vh",
					backgroundColor: "#232323",
				}}
			>
				<Typography style={{ margin: 5, fontSize: "2rem", color: "#B3B3B3" }}>
					Get some names
				</Typography>
				<Typography style={{ margin: 5, color: "#B3B3B3" }}>
					Click one to get another
				</Typography>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "row",
					}}
				>
					<GetNames />
					<GetNames />
				</Box>
			</Box>
		</MainLayout>
	);
};
