import { SerializedError, createSlice } from "@reduxjs/toolkit";
import { setRolesThunk } from "../../thunks/userThunks";

interface setRolesSlice {
	loading: string;
	error: SerializedError | null;
}

const initState: setRolesSlice = {
	loading: "idle",
	error: null,
};

const setRolesSlice = createSlice({
	name: "setRoles",
	initialState: initState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(setRolesThunk.fulfilled, state => {
			state.loading = "loaded";
		});
		builder.addCase(setRolesThunk.pending, state => {
			state.loading = "loading";
		});
		builder.addCase(setRolesThunk.rejected, (state, action) => {
			state.error = action.error;
			state.loading = "error";
		});
	},
});

export default setRolesSlice.reducer;
