import { SerializedError, createSlice } from "@reduxjs/toolkit";

import { loginThunk } from "../thunks/userThunks";
import { tokenResponse } from "../../types/token";

interface LoginState {
	tokens: tokenResponse;
	loading: string;
	error: SerializedError | null;
}

const initState: LoginState = {
	loading: "idle",
	tokens: {
		tokens: {
			accessToken: "",
			refreshToken: "",
		},
	},
	error: null,
};

const loginSlice = createSlice({
	name: "login",
	initialState: initState,
	reducers: {
		resetLoginState: state => {
			state.loading = initState.loading;
			state.tokens = initState.tokens;
			state.error = initState.error;
		},
	},
	extraReducers: builder => {
		builder.addCase(loginThunk.fulfilled, (state, action) => {
			state.tokens = action.payload;
			state.loading = "loaded";
		});
		builder.addCase(loginThunk.pending, state => {
			state.loading = "loading";
		});
		builder.addCase(loginThunk.rejected, (state, action) => {
			state.error = action.error;
			state.loading = "error";
		});
	},
});

export const { resetLoginState } = loginSlice.actions;

export default loginSlice.reducer;
