import { Box, CircularProgress } from "@mui/material";

interface AppLoaderProps {
	size?: string | number;
	wrapperMinHeight?: number | string;
}

// Primarly used for initial page loads. Will center in screen
const AppLoader = ({ size = "1rem", wrapperMinHeight }: AppLoaderProps) => {
	const thisIsAVariable = "1rem";
	return (
		<Box
			style={{ minHeight: wrapperMinHeight || "1rem" }}
			sx={{
				center: {
					display: "flex",
					margin: "auto",
					justifyContent: "center",
				},
			}}
		>
			<CircularProgress
				sx={{
					alignContent: "center",
					justifyContent: "center",
					bgcolorcolor: "#ffffff",
					color: "#ffffff",
				}}
				size={size}
			/>
		</Box>
	);
};

export default AppLoader;
