import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface ModalState {
	isActive: boolean;
	scrollPosition: number;
}

const initialState: ModalState = {
	isActive: false,
	scrollPosition: 0,
};

const modalHandlerSlice = createSlice({
	name: "modalHandler",
	initialState,
	reducers: {
		toggleModal: state => {
			if (!state.isActive) {
				state.scrollPosition = window.scrollY;
			}
			state.isActive = !state.isActive;
		},
	},
});

export const { toggleModal } = modalHandlerSlice.actions;

export const selectModalState = (state: RootState) => state.modal.isActive;

export const selectScrollPosition = (state: RootState) =>
	state.modal.scrollPosition;

export default modalHandlerSlice.reducer;
