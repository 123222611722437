import React, { FC } from "react";
import {
	AppBar,
	Box,
	Container,
	IconButton,
	Menu,
	Toolbar,
	Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { WebsiteColours } from "../../types/Colours";
import { TokenHasRoles } from "../../services/authService";

export const NavBar: FC = () => {
	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
		null
	);

	interface NavLinks {
		name: string;
		link: string;
	}
	const isAdmin = TokenHasRoles(["Admin"]);
	const isUser = TokenHasRoles(["User"]);

	const pages: NavLinks[] = [
		{ name: "Unit Conversions", link: "/unit" },
		{ name: "GitHub", link: "/github" },
		{ name: "Contact", link: "/contact" },
		{ name: "DndUtils", link: "/dnd" },
		{ name: "Game", link: "/game" },

		...(isAdmin ? [{ name: "Admin", link: "/admin" }] : []),

		...(isUser && !isAdmin ? [{ name: "Profile", link: "/profile" }] : []),

		...(!isUser && !isAdmin ? [{ name: "Login", link: "/login" }] : []),
	];

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const navigate = useNavigate();

	return (
		<AppBar sx={{ bgcolor: WebsiteColours.RichBlack }} position="static">
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<Typography
						variant="h6"
						noWrap
						component="a"
						href="/"
						sx={{
							mr: 2,
							display: { xs: "none", sm: "flex" },
							fontweight: 300,
							color: WebsiteColours.Eggshell,
							textDecoration: "none",
						}}
					>
						Alexander Goold
					</Typography>

					<Box sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}>
						<IconButton
							size="large"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							sx={{
								color: "#F0EAD6",
							}}
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: "block", md: "none" },
							}}
						>
							{pages.map(page => (
								<MenuItem key={page.name} onClick={handleCloseNavMenu}>
									<Typography
										component="a"
										onClick={() => navigate(page.link)}
										sx={{
											color: "inherit",
											textDecoration: "none",
										}}
										textAlign="center"
									>
										{page.name}
									</Typography>
								</MenuItem>
							))}
							{isAdmin || isUser ? (
								<MenuItem>
									<Typography
										component="a"
										onClick={() => {
											localStorage.removeItem("accessToken");
											localStorage.removeItem("refreshToken");
											navigate("/");
										}}
										sx={{
											color: "F0EAD6",
											textDecoration: "none",
										}}
										textAlign="center"
									>
										Logout
									</Typography>
								</MenuItem>
							) : null}
						</Menu>
					</Box>
					<Typography
						variant="h5"
						noWrap
						component="a"
						sx={{
							display: { xs: "flex", sm: "none" },
							flexGrow: 1,
							fontFamily: "sans-serif",
							fontWeight: 300,
							color: "#F0EAD6",
							textDecoration: "none",
						}}
					>
						Alexander Goold
					</Typography>
					<Box
						sx={{
							flexGrow: 1,
							display: { xs: "none", sm: "flex" },
							justifyContent: "space-around",
						}}
					>
						{pages.map(page => (
							<Button
								key={page.name}
								onClick={() => navigate(page.link)}
								sx={{
									color: "#F0EAD6",
									display: "block",
									":hover": {
										color: "#ffffff",
										backgroundColor: "transparent",
									},
								}}
							>
								<Typography
									component="a"
									sx={{
										display: { xs: "none", sm: "flex" },
										fontFamily: "sans-serif",
										fontWeight: 200,
										color: "inherit",
										textDecoration: "none",
									}}
								>
									{page.name}
								</Typography>
							</Button>
						))}
						{isAdmin || isUser ? (
							<Button
								onClick={() => {
									localStorage.removeItem("accessToken");
									localStorage.removeItem("refreshToken");
									navigate("/");
								}}
							>
								Logout
							</Button>
						) : null}
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
};

export default NavBar;
