import * as React from "react";
import { FC } from "react";
import Box from "@mui/material/Box";
import NavBar from "../NavBar/NavBar";
import { Footer } from "../Footer/Footer";

interface MainLayoutProps {
	children: React.ReactNode;
}

export const MainLayout: FC<MainLayoutProps> = ({ children }) => {
	const [mobileOpen, setMobileOpen] = React.useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	return (
		<Box>
			<NavBar />
			<Box component="main">{children}</Box>
			<Footer />
		</Box>
	);
};
