import jwtDecode from "jwt-decode";
import { getTokenFromLocalStorage } from "./sessionStorageServices";

interface IToken {
	email: string;
	sub: string;
	"http://schemas.microsoft.com/ws/2008/06/identity/claims/role": string[];
	exp: number;
	iss: string;
	aud: string;
}

export const TokenHasRoles = (roleNames: string[]) => {
	const token = getTokenFromLocalStorage();

	const roles = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

	if (token) {
		const decodedToken: IToken = jwtDecode(token);
		if (typeof decodedToken[roles] === "string") {
			decodedToken[roles] = [decodedToken[roles]];
		}

		if (
			decodedToken &&
			decodedToken[roles].some(role => roleNames.includes(role))
		) {
			return true;
		}
	}

	return false;
};

export const TokenIsExpired = () => {
	const token = getTokenFromLocalStorage();
	if (token) {
		const decodedToken: IToken = jwtDecode(token);
		const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
		const currentTime = Date.now();

		if (currentTime <= expirationTime) {
			return false;
		}
		return true;
	}

	return true;
};

export const GetRolesFromToken = () => {
	const token = getTokenFromLocalStorage();
	const roles = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

	if (token) {
		const decodedToken: IToken = jwtDecode(token);
		if (typeof decodedToken[roles] === "string") {
			decodedToken[roles] = [decodedToken[roles]];
		}
		return decodedToken[roles];
	}
	return null;
};
