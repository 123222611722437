import { GridColumn } from "../types/DataGridTypes";

export const userColumns: GridColumn[] = [
	{
		field: "firstName",
		headerName: "First Name",
	},
	{
		field: "lastName",
		headerName: "Last Name",
	},
	{
		field: "email",
		headerName: "Email",
	},
	{
		field: "name",
		headerName: "Username",
	},
	{
		field: "role",
		headerName: "Roles",
	},
];
