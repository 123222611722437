import { FC, ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GetRolesFromToken } from "../services/authService";
import { routeFactory } from "../routes/routeFactory";

interface Props {
	children: ReactNode;
	roles: string[];
}

export const RoleBasedRoute: FC<Props> = ({ roles, children }) => {
	const userRoles = GetRolesFromToken();
	const navigate = useNavigate();

	useEffect(() => {
		if (!userRoles) {
			navigate(routeFactory.appRoot());
			return;
		}

		if (!userRoles.some(role => roles.includes(role))) {
			navigate(routeFactory.appRoot());
		}
	}, [children]);
	return <>{children}</>;
};
