interface ExampleParams {
	exampleId: string;
}

interface TokenParams {
	token: string;
}

export const routeFactory = {
	appRoot: () => "/",
	unit: () => "/unit",
	github: () => "/github",
	login: () => "/login",
	register: () => "/register",
	dndutils: () => "/dnd",
	profile: () => "/profile",
	logout: () => "/logout",
	admin: () => "/admin",
	game: () => "/game",
	resetPasswordByEmail: ({ token }: TokenParams) => `/resetPassword/${token}`,
};
