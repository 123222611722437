import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface CatFact {
	fact: string;
	length: number;
}

export const testSlice = createApi({
	reducerPath: "test",
	tagTypes: ["Cats"],
	baseQuery: fetchBaseQuery({
		baseUrl: "https://catfact.ninja",
	}),
	endpoints: builder => ({
		getCatFact: builder.query<CatFact, void>({
			query: () => ({
				url: "/fact",
			}),
			providesTags: ["Cats"],
		}),
	}),
});

export const { useGetCatFactQuery } = testSlice;
