import { createAsyncThunk } from "@reduxjs/toolkit";
import { Token } from "../../types/token";
import { UserApi } from "../../services/userApiService";

export const validateTokenThunk = createAsyncThunk(
	"tokenSlice/validateToken",
	async (token: Token) => {
		const response = await UserApi.validateToken(token);
		const data = await response.json();

		if (!response.ok) {
			throw new Error(data.error);
		}

		return data;
	}
);
