import { Grid } from "@mui/material";
import { LoginForm } from "../LoginForm";

export const LoginPage = () => (
	<Grid
		container
		spacing={0}
		direction="column"
		alignItems="center"
		justifyContent="center"
		sx={{
			minHeight: "100vh",
			backgroundImage:
				"url(https://images.unsplash.com/photo-1573561507036-b0110570e2e3?q=80&w=1587&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",

			backgroundSize: "cover",
		}}
	>
		<Grid item xs={3} padding={3}>
			<LoginForm />
		</Grid>
	</Grid>
);
