import {
	Box,
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	InputAdornment,
	TextField,
	Tooltip,
} from "@mui/material";
import { FC, useState } from "react";
import { MainLayout } from "./MainLayout";

export const UnitConversions: FC = () => {
	const [grPerCmSqValue, setGrPerCmSqValue] = useState("");
	const [kiloPerMeterSqValue, setKiloPerMeterSqValue] = useState("");

	const [AMUValue, setAMUValue] = useState("");
	const [KiloValue, setKiloValue] = useState("");

	const [ElectronVoltsValue, setElectronVoltsValue] = useState("");
	const [JoulesValue, setJoulesValue] = useState("");

	const [cm2ToM2Value, setCm2ToM2Value] = useState("");
	const [m2ToCm2Value, setM2ToCm2Value] = useState("");

	const [cm3Tom3Value, setCm3Tom3Value] = useState("");
	const [m3ToCm3Value, setM3ToCm3Value] = useState("");

	const [MsToKphValue, setMsToKphValue] = useState("");
	const [KphToMsValue, setKphToMsValue] = useState("");

	const handleMsToKphChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setMsToKphValue(value);
		const ms = parseFloat(value);
		if (!Number.isNaN(ms)) {
			const kph = (ms * 3.6).toPrecision(8);
			setKphToMsValue(kph);
		} else {
			setKphToMsValue("");
		}
	};

	const handleKphToMsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setKphToMsValue(value);
		const kph = parseFloat(value);
		if (!Number.isNaN(kph)) {
			const ms = (kph / 3.6).toPrecision(8);
			setMsToKphValue(ms);
		} else {
			setMsToKphValue("");
		}
	};

	const handlecm3Tom3Change = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setCm3Tom3Value(value);
		const cm3 = parseFloat(value);
		if (!Number.isNaN(cm3)) {
			const m3 = (cm3 / 1000000).toPrecision(8);
			setM3ToCm3Value(m3);
		} else {
			setM3ToCm3Value("");
		}
	};

	const handlem3ToCm3Change = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setM3ToCm3Value(value);
		const m3 = parseFloat(value);
		if (!Number.isNaN(m3)) {
			const cm3 = (m3 * 1000000).toPrecision(8);
			setCm3Tom3Value(cm3);
		} else {
			setCm3Tom3Value("");
		}
	};

	const handlecm2ToM2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setCm2ToM2Value(value);
		const cm2 = parseFloat(value);
		if (!Number.isNaN(cm2)) {
			const m2 = (cm2 / 10000).toPrecision(8);
			setM2ToCm2Value(m2);
		} else {
			setM2ToCm2Value("");
		}
	};

	const handlem2ToCm2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setM2ToCm2Value(value);
		const m2 = parseFloat(value);
		if (!Number.isNaN(m2)) {
			const cm2 = (m2 * 10000).toPrecision(8);
			setCm2ToM2Value(cm2);
		} else {
			setCm2ToM2Value("");
		}
	};

	const handleJoulesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setJoulesValue(value);
		const joules = parseFloat(value);
		if (!Number.isNaN(joules)) {
			const electronVolts = (joules / 1.602e-19).toPrecision(8);
			setElectronVoltsValue(electronVolts);
		} else {
			setElectronVoltsValue("");
		}
	};

	const handleElectronVoltsChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const { value } = event.target;
		setElectronVoltsValue(value);
		const electronVolts = parseFloat(value);
		if (!Number.isNaN(electronVolts)) {
			const joules = (electronVolts * 1.602e-19).toPrecision(8);
			setJoulesValue(joules);
		} else {
			setJoulesValue("");
		}
	};

	const handleAMUChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setAMUValue(value);
		const AMU = parseFloat(value);
		if (!Number.isNaN(AMU)) {
			const kilos = (AMU * 1.6605e-27).toPrecision(8);
			setKiloValue(kilos);
		} else {
			setKiloValue("");
		}
	};

	const handleKiloChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setKiloValue(value);
		const kilos = parseFloat(value);
		if (!Number.isNaN(kilos)) {
			const AMU = (kilos / 1.6605e-27).toPrecision(8);
			setAMUValue(AMU);
		} else {
			setAMUValue("");
		}
	};

	const handleGrPerCmSqChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const { value } = event.target;
		setGrPerCmSqValue(value);
		const gramsPerCmSq = parseFloat(value.replace(/[^0-9.]/g, ""));
		if (!Number.isNaN(gramsPerCmSq)) {
			const kilosPerMeterSq = (gramsPerCmSq / 0.001).toFixed(4);
			setKiloPerMeterSqValue(kilosPerMeterSq);
		} else {
			setKiloPerMeterSqValue("");
		}
	};

	const handlekiloPerMeterSqChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const { value } = event.target;
		setKiloPerMeterSqValue(value);
		const kilosPerMeterSq = parseFloat(value.replace(/[^0-9.]/g, ""));
		if (!Number.isNaN(kilosPerMeterSq)) {
			const gramsPerCmSq = (kilosPerMeterSq * 0.001).toFixed(4);
			setGrPerCmSqValue(gramsPerCmSq);
		} else {
			setGrPerCmSqValue("");
		}
	};

	return (
		<MainLayout>
			<Box
				component="form"
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					backgroundColor: "#111111",
					minHeight: "100vh",
				}}
			>
				<Grid
					container
					sx={{
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Grid margin="1rem" item xs={4}>
						<Tooltip
							disableFocusListener
							title="multiply the mass / volume value by 1000"
						>
							<TextField
								sx={{
									backgroundColor: "#ffffff",
									marginY: "1rem",
								}}
								id="grPerCmSq"
								fullWidth
								label="Gram per Centimeter Cubed"
								name="grPerCmSq"
								variant="filled"
								autoComplete="off"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">g/cm3</InputAdornment>
									),
								}}
								value={grPerCmSqValue}
								onChange={handleGrPerCmSqChange}
							/>
						</Tooltip>
						<Tooltip
							disableFocusListener
							title="divide the mass / volume value by 1000"
						>
							<TextField
								sx={{
									backgroundColor: "#ffffff",
									marginY: "1rem",
								}}
								id=""
								fullWidth
								label="Atomic Mass Units to Kilos"
								name=""
								variant="filled"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">u</InputAdornment>
									),
								}}
								value={AMUValue}
								onChange={handleAMUChange}
							/>
						</Tooltip>
						<Tooltip
							disableFocusListener
							title="multiply the energy value by 1.602e-19"
						>
							<TextField
								sx={{
									backgroundColor: "#ffffff",
									marginY: "1rem",
								}}
								id=""
								fullWidth
								label="Electron Volts to Joules"
								variant="filled"
								name=""
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">eV</InputAdornment>
									),
								}}
								value={ElectronVoltsValue}
								onChange={handleElectronVoltsChange}
							/>
						</Tooltip>
						<Tooltip
							disableFocusListener
							title="Divide the area value by 10000"
						>
							<TextField
								sx={{
									backgroundColor: "#ffffff",
									marginY: "1rem",
								}}
								id=""
								fullWidth
								label="Centimeters Squared to Meters Squared"
								variant="filled"
								name=""
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">cm2</InputAdornment>
									),
								}}
								value={cm2ToM2Value}
								onChange={handlecm2ToM2Change}
							/>
						</Tooltip>
						<Tooltip
							disableFocusListener
							title="Divide the volume value by 1000000"
						>
							<TextField
								sx={{
									backgroundColor: "#ffffff",
									marginY: "1rem",
								}}
								id="grPerCmSq"
								fullWidth
								label="Centimeters Cubed to Meters Cubed"
								name="grPerCmSq"
								variant="filled"
								autoComplete="off"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">cm3</InputAdornment>
									),
								}}
								value={cm3Tom3Value}
								onChange={handlecm3Tom3Change}
							/>
						</Tooltip>
						<Tooltip
							disableFocusListener
							title="multiply the speed value by 3,6"
						>
							<TextField
								sx={{
									backgroundColor: "#ffffff",
									marginY: "1rem",
								}}
								id="grPerCmSq"
								fullWidth
								label="M/s to Kph"
								name="MsToKph"
								variant="filled"
								autoComplete="off"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">m/s</InputAdornment>
									),
								}}
								value={MsToKphValue}
								onChange={handleMsToKphChange}
							/>
						</Tooltip>
					</Grid>
					<Grid margin="1rem" item xs={4}>
						<Tooltip
							disableFocusListener
							title="divide the mass / volume value by 1000"
						>
							<TextField
								sx={{
									backgroundColor: "#ffffff",
									marginY: "1rem",
								}}
								id="kiloPerMeterSq"
								fullWidth
								label="Kilo Per Meter Cubed"
								variant="filled"
								name="kiloPerMeterSq"
								autoComplete="off"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">kg/m3</InputAdornment>
									),
								}}
								value={kiloPerMeterSqValue}
								onChange={handlekiloPerMeterSqChange}
							/>
						</Tooltip>
						<Tooltip
							disableFocusListener
							title="multiply the mass value by 6,022×10^+26"
						>
							<TextField
								sx={{
									backgroundColor: "#ffffff",
									marginY: "1rem",
								}}
								id=""
								fullWidth
								variant="filled"
								label="Kilos To Atomic Mass Units"
								autoComplete="off"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">kg</InputAdornment>
									),
								}}
								value={KiloValue}
								onChange={handleKiloChange}
							/>
						</Tooltip>
						<Tooltip
							disableFocusListener
							title="divide the energy value by 1.602e-19"
						>
							<TextField
								sx={{
									backgroundColor: "#ffffff",
									marginY: "1rem",
								}}
								id=""
								fullWidth
								variant="filled"
								label="Joules to Electron Volts"
								name=""
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">J</InputAdornment>
									),
								}}
								value={JoulesValue}
								onChange={handleJoulesChange}
							/>
						</Tooltip>
						<Tooltip
							disableFocusListener
							title="Multiply the area value by 10000"
						>
							<TextField
								sx={{
									backgroundColor: "#ffffff",
									marginY: "1rem",
								}}
								id=""
								fullWidth
								variant="filled"
								label="Meters Squared to Centimeters Squared"
								name=""
								autoComplete="off"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">m2</InputAdornment>
									),
								}}
								value={m2ToCm2Value}
								onChange={handlem2ToCm2Change}
							/>
						</Tooltip>
						<Tooltip
							disableFocusListener
							title="Multiply the volume value by 1000000"
						>
							<TextField
								sx={{
									backgroundColor: "#ffffff",
									marginY: "1rem",
								}}
								id="grPerCmSq"
								fullWidth
								variant="filled"
								label="Meter Cubed to Centimeter Cubed"
								name="m3ToCm3"
								autoComplete="off"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">m3</InputAdornment>
									),
								}}
								value={m3ToCm3Value}
								onChange={handlem3ToCm3Change}
							/>
						</Tooltip>
						<Tooltip disableFocusListener title="divide the speed value by 3,6">
							<TextField
								sx={{
									backgroundColor: "#ffffff",
									marginY: "1rem",
								}}
								id="grPerCmSq"
								fullWidth
								variant="filled"
								label="Kph to M/s"
								name="KphToMs"
								autoComplete="off"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">kph</InputAdornment>
									),
								}}
								value={KphToMsValue}
								onChange={handleKphToMsChange}
							/>
						</Tooltip>
					</Grid>
				</Grid>
			</Box>
		</MainLayout>
	);
};
