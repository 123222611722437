import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { BouncyHead } from "../BouncyHead/BouncyHead";

export const Game = () => {
	const [heads, setHeads] = useState([1]);

	const addBouncyHead = () => {
		setHeads([...heads, Math.random()]);
	};

	useEffect(() => {
		if (heads.length === 0) {
			alert("You win!");
		}
	}, [heads]);

	return (
		<Box>
			<Box>
				{heads.map((_, index) => (
					<BouncyHead
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						id={Math.floor(Math.random() * 1001)}
					/>
				))}
			</Box>
			<Box>
				<Typography>Get rid of all the heads!</Typography>
				<Button onClick={addBouncyHead}>Increase difficulty</Button>
			</Box>
		</Box>
	);
};
