import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@mui/material";
import { FC } from "react";

interface Props {
	open: boolean;
	onClose: () => void;
	valueToEdit: string;
}

export const EditValueDialogue: FC<Props> = ({
	open,
	onClose,
	valueToEdit,
}) => {
	const handUpdateValue = () => {
		// handle update value here
	};
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Edit Value</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Value"
					type="text"
					fullWidth
					defaultValue={valueToEdit}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button onClick={onClose}>Save</Button>
			</DialogActions>
		</Dialog>
	);
};
