import { Box } from "@mui/material";
import { FC } from "react";
import { Game } from "../Game/Game";
import { MainLayout } from "./MainLayout";

export const GamePage: FC = () => {
	const placeholder = 1;
	return (
		<MainLayout>
			<Box
				sx={{
					display: "flex",
					justifyContent: "top",
					alignItems: "center",
					flexDirection: "column",
					minHeight: "100vh",
					backgroundColor: "#232323",
				}}
			>
				<Box>
					<Game />
				</Box>
			</Box>
		</MainLayout>
	);
};
