import { CreateNameModel } from "../types/names";
import { getTokenFromLocalStorage } from "./sessionStorageServices";

class DndutilsService {
	private nameBaseUrl = "https://sandyssuperapi.azurewebsites.net/name";

	private carousalBaseUrl = "https://sandyssuperapi.azurewebsites.net/carousal";

	public async getAllNames() {
		const response = await fetch(`${this.nameBaseUrl}/getAll`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getTokenFromLocalStorage()}`,
			},
		}).catch(err => {
			throw new Error(err.message);
		});

		return response;
	}

	public async getAllFirstName() {
		const response = await fetch(`${this.nameBaseUrl}/getAllFirst`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getTokenFromLocalStorage()}`,
			},
		}).catch(err => {
			throw new Error(err.message);
		});

		return response;
	}

	public async getAllLastName() {
		const response = await fetch(`${this.nameBaseUrl}/getAllLast`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getTokenFromLocalStorage()}`,
			},
		}).catch(err => {
			throw new Error(err.message);
		});

		return response;
	}

	public async updateName(id: string) {
		const response = await fetch(`${this.nameBaseUrl}/update/${id}`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getTokenFromLocalStorage()}`,
			},
		}).catch(err => {
			throw new Error(err.message);
		});

		return response;
	}

	public async deleteName(id: string) {
		const response = await fetch(`${this.nameBaseUrl}/delete/${id}`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getTokenFromLocalStorage()}`,
			},
		}).catch(err => {
			throw new Error(err.message);
		});

		return response;
	}

	public async addName(name: CreateNameModel) {
		const response = await fetch(`${this.nameBaseUrl}/add`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getTokenFromLocalStorage()}`,
			},
			body: JSON.stringify(name),
		}).catch(err => {
			throw new Error(err.message);
		});

		return response;
	}

	public async getAllCarousals() {
		const response = await fetch(`${this.carousalBaseUrl}/getAll`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getTokenFromLocalStorage()}`,
			},
		}).catch(err => {
			throw new Error(err.message);
		});

		return response;
	}
}

export const DndApi = new DndutilsService();
