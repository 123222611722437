import { RefreshAccessTokenDto, tokenResponse } from "../types/token";

export const saveTokensToLocalStorage = (tokens: tokenResponse) => {
	localStorage.setItem("accessToken", tokens.tokens.accessToken);
	localStorage.setItem("refreshToken", tokens.tokens.refreshToken);
};

export const saveTokenToLocalStorage = (token: string) => {
	localStorage.setItem("accessToken", token);
};

export const saveRefreshTokenToLocalStorage = (token: string) => {
	localStorage.setItem("refreshToken", token);
};

export const getTokenFromLocalStorage = (): string | null => {
	const token = localStorage.getItem("accessToken");
	return token;
};

export const clearTokenFromLocalStorage = () => {
	localStorage.removeItem("accessToken");
	localStorage.removeItem("refreshToken");
};

export const getRefreshTokenFromLocalStorage = (): string | null => {
	const token = localStorage.getItem("refreshToken");
	return token;
};

export const getRefreshAndAccessTokenFromLocalStorage =
	(): RefreshAccessTokenDto | null => {
		let refreshAccessTokenDto: RefreshAccessTokenDto;
		const accessToken = localStorage.getItem("accessToken");
		const refreshToken = localStorage.getItem("refreshToken");
		if (refreshToken && accessToken) {
			refreshAccessTokenDto = {
				accessToken,
				refreshToken,
			};
			return refreshAccessTokenDto;
		}
		return null;
	};
