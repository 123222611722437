import { Avatar, Box, Button, Typography } from "@mui/material";
import Sandy from "../../assets/images/SandyTransparent.webp";
import { MainLayout } from "./MainLayout";

export const GitHubPage = () => {
	const handleClick = () => {
		window.location.href = "https://www.github.com/alexgoold";
	};

	const increaseSpeed = () => {
		const bouncyBoi = document.querySelector(".bouncyBoi") as HTMLElement;

		if (bouncyBoi) {
			const currentXSpeedInSeconds = getComputedStyle(
				bouncyBoi
			).getPropertyValue("--animation-speed-x");
			const currentYSpeedInSeconds = getComputedStyle(
				bouncyBoi
			).getPropertyValue("--animation-speed-y");

			// Remove the 's' from the string
			const currentXSpeed = parseFloat(currentXSpeedInSeconds.slice(0, -1));
			const currentYSpeed = parseFloat(currentYSpeedInSeconds.slice(0, -1));

			const newXSpeed = currentXSpeed * 0.95;
			const newYSpeed = currentYSpeed * 0.95;

			bouncyBoi.style.setProperty("--animation-speed-x", `${newXSpeed}s`);
			bouncyBoi.style.setProperty("--animation-speed-y", `${newYSpeed}s`);
		}
	};

	return (
		<MainLayout>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					backgroundColor: "#111111",
					minHeight: "100vh",
				}}
				onClick={increaseSpeed}
			>
				<Typography
					sx={{
						color: "#ffffff",
						fontSize: "3rem",
						fontWeight: "bold",
						textAlign: "center",
						marginBottom: "2rem",
					}}
				>
					Click my Face to see GitHub
				</Typography>
				<Box>
					<Avatar
						className="bouncyBoi"
						src={Sandy}
						sx={{
							marginTop: "0",
							marginBottom: "0",
							opacity: "1",
							transition: "box-shadow 0.3s ease-in-out",
							":hover": {
								boxShadow: "0px 0px 10px 5px rgba(255,255,255,0.5)",
								cursor: "pointer",
							},
						}}
						variant="circular"
						onMouseDown={handleClick}
						alt="Sandy"
					/>
				</Box>
				<Button
					variant="contained"
					sx={{ marginTop: "8rem", bgcolor: "#ffffff", color: "#111111" }}
					onClick={() => {
						window.location.href = "https://www.github.com/alexgoold";
					}}
				>
					Or this button
				</Button>
			</Box>
		</MainLayout>
	);
};
