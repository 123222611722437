import React, { FC, useEffect, useState } from "react";
import { Avatar } from "@mui/material";
import Sandy from "../../assets/images/SandyTransparent.webp";

interface BouncyHeadProps {
	id: number;
	// eslint-disable-next-line react/no-unused-prop-types
}

export const BouncyHead: FC<BouncyHeadProps> = ({ id }) => {
	const [isVisible, setIsVisible] = useState(true);

	const getRandomNumberBetween = (min: number, max: number) =>
		Math.random() * (max - min) + min;

	const xSpeed = getRandomNumberBetween(0.5, 5);
	const ySpeed = getRandomNumberBetween(0.5, 5);

	useEffect(() => {
		const bouncyBoi = document.getElementById(id.toString());

		if (bouncyBoi) {
			bouncyBoi.style.setProperty("--animation-speed-x", `${xSpeed}s`);
			bouncyBoi.style.setProperty("--animation-speed-y", `${ySpeed}s`);
		}
	}, [id, xSpeed, ySpeed]);

	const removeBouncyHead = () => {
		setIsVisible(false);
	};

	return isVisible ? (
		<Avatar
			id={id.toString()}
			className="bouncyBoi"
			src={Sandy}
			sx={{
				marginTop: "0",
				marginBottom: "0",
				opacity: "1",
				transition: "box-shadow 0.3s ease-in-out",
				":hover": {
					boxShadow: "0px 0px 10px 5px rgba(255,255,255,0.5)",
					cursor: "pointer",
				},
			}}
			variant="circular"
			alt="Sandy"
			onMouseDown={removeBouncyHead}
		/>
	) : null;
};
