import * as React from "react";
import { Box, Button, Grid } from "@mui/material";
import { FC } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { Facebook, LinkedIn } from "@mui/icons-material";
import { WebsiteColours } from "../../types/Colours";

export const Footer: FC = () => {
	const getfucked = "getfucked";

	return (
		<Box
			component="footer"
			sx={{
				bgcolor: WebsiteColours.RichBlack,
			}}
		>
			<Container
				sx={{
					color: "#bfbfbf",
				}}
				maxWidth="lg"
			>
				<Grid
					sx={{
						alignItems: "center",
						justifyContent: "space-between",
					}}
					container
					spacing={5}
				>
					<Grid item xs={12} sm={4}>
						<Typography variant="h6">Contact Me</Typography>

						<Typography variant="body2">
							Email: sandygoold.net@gmail.com
						</Typography>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Typography variant="h6">Follow me</Typography>
						<Button
							sx={{
								color: "#F0EAD6",
								":hover": {
									color: "#ffffff",
									backgroundColor: "transparent",
								},
							}}
							onClick={() => {
								window.location.href =
									"https://www.linkedin.com/in/alexander-goold-005821265/";
							}}
						>
							<LinkedIn />
						</Button>
					</Grid>
				</Grid>
				<Box mt={5}>
					<Typography variant="body2" align="center">
						Copyright ©{new Date().getFullYear()}
					</Typography>
				</Box>
			</Container>
		</Box>
	);
};
