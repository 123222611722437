import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserApi } from "../../services/userApiService";
import {
	ChangePassword,
	LoginUser,
	RegisterUser,
	RequestPasswordReset,
	ResetPasswordWithToken,
	UpdateUser,
} from "../../types/user";
import { LogoutCurrentDto, RefreshAccessTokenDto } from "../../types/token";

export const loginThunk = createAsyncThunk(
	"loginSlice/login",
	async (loginDto: LoginUser) => {
		const response = await UserApi.loginUser(loginDto);
		const data = await response.json();

		if (!response.ok) {
			throw new Error(data);
		}

		return data;
	}
);

export const logoutCurrentThunk = createAsyncThunk(
	"logoutCurrentSlice/logoutCurrent",
	async (refreshToken: LogoutCurrentDto) => {
		const response = await UserApi.logoutCurrent(refreshToken);

		if (!response.ok) {
			const data = await response.json();
			throw new Error(data.error);
		}
	}
);

export const refreshTokenThunk = createAsyncThunk(
	"refreshTokenSlice/refreshToken",
	async (refreshToken: RefreshAccessTokenDto) => {
		const response = await UserApi.refreshToken(refreshToken);
		const data = await response.json();

		if (!response.ok) {
			throw new Error(data);
		}

		return data;
	}
);

export const getAllUsersThunk = createAsyncThunk(
	"getAllUsersSlice/getAllUsers",
	async () => {
		const response = await UserApi.getAllUsers();
		const data = await response.json();

		if (!response.ok) {
			throw new Error(data);
		}

		return data;
	}
);

export const getUserByIdThunk = createAsyncThunk(
	"getUserByIdSlice/getUserById",
	async (id: string) => {
		const response = await UserApi.getUserById(id);
		const data = await response.json();

		if (!response.ok) {
			throw new Error(data);
		}

		return data;
	}
);

export const updateUserThunk = createAsyncThunk(
	"updateUserSlice/updateUser",
	async ({ id, user }: { id: string; user: UpdateUser }) => {
		const response = await UserApi.updateUser(id, user);

		if (!response.ok) {
			const data = await response.json();
			throw new Error(data.error);
		}
	}
);

export const deleteUserThunk = createAsyncThunk(
	"deleteUserSlice/deleteUser",
	async (id: string) => {
		const response = await UserApi.deleteUser(id);

		if (!response.ok) {
			const data = await response.json();
			throw new Error(data);
		}
	}
);

export const changePasswordThunk = createAsyncThunk(
	"changePasswordSlice/changePassword",
	async (changePasswordDto: ChangePassword) => {
		const response = await UserApi.changePassword(changePasswordDto);

		if (!response.ok) {
			const data = await response.json();
			throw new Error(data);
		}
	}
);

export const getCurrentUserThunk = createAsyncThunk(
	"getCurrentUserSlice/getCurrentUser",
	async () => {
		const response = await UserApi.getCurrentUser();
		const data = await response.json();

		if (!response.ok) {
			throw new Error(data);
		}

		return data;
	}
);

export const setRolesThunk = createAsyncThunk(
	"setRolesSlice/setRoles",
	async ({ id, roles }: { id: string; roles: string[] }) => {
		const response = await UserApi.setRoles(id, roles);

		if (!response.ok) {
			const data = await response.json();
			throw new Error(data);
		}
	}
);

export const registerUserThunk = createAsyncThunk(
	"registerUserSlice/registerUser",
	async ({ user, apikey }: { user: RegisterUser; apikey: string }) => {
		const response = await UserApi.registerUser(user, apikey);

		if (!response.ok) {
			const data = await response.json();
			throw new Error(data);
		}
	}
);

export const requestPasswordResetThunk = createAsyncThunk(
	"requestPasswordResetSlice/requestPasswordReset",
	async (ResetRequestDto: RequestPasswordReset) => {
		const response = await UserApi.requestPasswordReset(ResetRequestDto);

		if (!response.ok) {
			const data = await response.json();
			throw new Error(data);
		}
	}
);

export const resetPasswordWithTokenThunk = createAsyncThunk(
	"resetPasswordWithTokenSlice/resetPasswordWithToken",
	async (dto: ResetPasswordWithToken) => {
		const response = await UserApi.resetPasswordWithToken(dto);

		if (!response.ok) {
			const data = await response.json();
			throw new Error(data);
		}
	}
);
