import { createSlice } from "@reduxjs/toolkit";
import { validateTokenResponse } from "../../types/token";
import { validateTokenThunk } from "../thunks/tokenThunk";

interface validateTokenState {
	loading: string;
	error: string | undefined;
	validateTokenResponse: validateTokenResponse;
}

const initState: validateTokenState = {
	loading: "idle",
	error: undefined,
	validateTokenResponse: {
		isValid: false,
		message: "",
	},
};

const validateTokenSlice = createSlice({
	name: "validateToken",
	initialState: initState,
	reducers: {
		resetValidateTokenState: state => {
			state.loading = initState.loading;
			state.validateTokenResponse = initState.validateTokenResponse;
			state.error = initState.error;
		},
	},
	extraReducers: builder => {
		builder.addCase(validateTokenThunk.fulfilled, (state, action) => {
			state.validateTokenResponse = action.payload;
			state.loading = "loaded";
		});
		builder.addCase(validateTokenThunk.pending, state => {
			state.loading = "loading";
		});
		builder.addCase(validateTokenThunk.rejected, (state, action) => {
			state.error = action.error.message;
			state.loading = "error";
		});
	},
});

export const { resetValidateTokenState } = validateTokenSlice.actions;

export default validateTokenSlice.reducer;
