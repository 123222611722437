import { SerializedError, createSlice } from "@reduxjs/toolkit";
import { getUserByIdThunk } from "../../thunks/userThunks";
import { GetUserByIdResponse } from "../../../types/user";

interface UserState {
	UserResponse: GetUserByIdResponse;
	loading: string;
	error: SerializedError | null;
}

const initState: UserState = {
	loading: "idle",
	UserResponse: {} as GetUserByIdResponse,
	error: null,
};

const getUserByIdSlice = createSlice({
	name: "getUserById",
	initialState: initState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getUserByIdThunk.fulfilled, (state, action) => {
			state.UserResponse = action.payload;
			state.loading = "loaded";
		});
		builder.addCase(getUserByIdThunk.pending, state => {
			state.loading = "loading";
		});
		builder.addCase(getUserByIdThunk.rejected, (state, action) => {
			state.error = action.error;
			state.loading = "error";
		});
	},
});

export default getUserByIdSlice.reducer;
