import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { StylesReset } from "./styles/StyleReset";
import { CssVariables } from "./styles/CssVariables";
import { GlobalStyles } from "./styles/GlobalStyles";
import { store } from "./store/store";

const container = document.getElementById("root");

const root = createRoot(container as Element);
root.render(
	<Provider store={store}>
		<StylesReset />
		<CssVariables />
		<GlobalStyles />
		<App />
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
