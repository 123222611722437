import { FC, ReactNode, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks/useAppSelector";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { Token } from "../types/token";
import { TokenIsExpired } from "../services/authService";
import {
	getRefreshAndAccessTokenFromLocalStorage,
	saveRefreshTokenToLocalStorage,
	saveTokenToLocalStorage,
	saveTokensToLocalStorage,
} from "../services/sessionStorageServices";
import { resetValidateTokenState } from "../store/slices/validateTokenSlice";
import { validateTokenThunk } from "../store/thunks/tokenThunk";
import { refreshTokenThunk } from "../store/thunks/userThunks";
import { resetRefreshState } from "../store/slices/Users/refreshTokenSlice";

interface Props {
	children: ReactNode;
}

export const ProtectedRoute: FC<Props> = ({ children }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const localStorageTokens = getRefreshAndAccessTokenFromLocalStorage();

	const {
		loading: validateLoading,
		error: validateError,
		validateTokenResponse,
	} = useAppSelector(state => state.validateToken);
	const {
		loading: refreshLoading,
		error: refreshError,
		refreshTokenResponse,
	} = useAppSelector(state => state.refreshToken);

	useEffect(() => {
		if (!localStorageTokens) {
			navigate("/");
			return;
		}

		const validateAccessToken: Token = {
			tokenToValidate: localStorageTokens.accessToken,
		};
		dispatch(validateTokenThunk(validateAccessToken));
	}, []);

	useEffect(() => {
		if (TokenIsExpired() && localStorageTokens) {
			dispatch(resetValidateTokenState());
			dispatch(refreshTokenThunk(localStorageTokens));
		}
	}, [children]);

	useEffect(() => {
		if (refreshTokenResponse && refreshLoading === "loaded") {
			const access = refreshTokenResponse.tokenData.accessToken;
			const refresh = refreshTokenResponse.tokenData.refreshToken;
			saveTokenToLocalStorage(access);
			saveRefreshTokenToLocalStorage(refresh);
			dispatch(resetRefreshState());
		}
	}, [refreshLoading]);

	if (
		(validateError && validateLoading === "error") ||
		(refreshError && refreshLoading === "error") ||
		(validateTokenResponse.isValid === false && validateLoading === "loaded")
	) {
		dispatch(resetValidateTokenState());
		dispatch(resetRefreshState());
		return <Navigate to="/" />;
	}

	return <>{children}</>;
};
