import { configureStore } from "@reduxjs/toolkit";
import modalHandlerSlice from "./slices/modalHandlerSlice";
import { testSlice } from "./slices/testSlice";
import loginSlice from "./slices/loginSlice";
import getUserByIdSlice from "./slices/Users/getUserByIdSlice";
import logoutCurrentSlice from "./slices/Users/logoutCurrentSlice";
import refreshTokenSlice from "./slices/Users/refreshTokenSlice";
import registerUserSlice from "./slices/Users/registerUserSlice";
import setRolesSlice from "./slices/Users/setRolesSlice";
import validateTokenSlice from "./slices/validateTokenSlice";
import getAllNamesSlice from "./slices/getAllNamesSlice";
import getAllUsersSlice from "./slices/Users/getAllUsersSlice";

export const store = configureStore({
	reducer: {
		[testSlice.reducerPath]: testSlice.reducer,
		modal: modalHandlerSlice,
		login: loginSlice,
		refreshToken: refreshTokenSlice,
		setRoles: setRolesSlice,
		registerUser: registerUserSlice,
		validateToken: validateTokenSlice,
		logoutCurretn: logoutCurrentSlice,
		userById: getUserByIdSlice,
		allNames: getAllNamesSlice,
		users: getAllUsersSlice,
	},
	// Creates automatic cache collection, automatic refetching etc.
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(testSlice.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
