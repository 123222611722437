import { useEffect, FC, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";
import { green, grey } from "@mui/material/colors";
import { LoginUser } from "../../types/user";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { saveTokensToLocalStorage } from "../../services/sessionStorageServices";
import { resetLoginState } from "../../store/slices/loginSlice";
import logo from "../../assets/images/logo-color.png";
import {
	loginThunk,
	requestPasswordResetThunk,
} from "../../store/thunks/userThunks";
import AppLoader from "../Shared/AppLoader";

export const LoginForm: FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { loading, error, tokens } = useAppSelector(state => state.login);
	const [open, setOpen] = useState(false);
	const [resetEmail, setEmail] = useState("");

	useEffect(() => {
		if (tokens && loading === "loaded") {
			console.log(tokens);
			saveTokensToLocalStorage(tokens);
			dispatch(resetLoginState());
		}
	}, [tokens]);

	const HandleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const loginData: LoginUser = {
			email: data.get("email") as string,
			password: data.get("password") as string,
		};
		dispatch(loginThunk(loginData));
	};

	const HandleClick = () => {
		setOpen(true);
	};

	const HandleRegisterClick = () => {
		navigate("/register");
	};

	return (
		<Container
			component="main"
			maxWidth="xs"
			sx={{
				backgroundColor: "white",
				borderRadius: "15px",
				paddingY: "10px",
			}}
		>
			<Dialog open={open} onClose={() => setOpen(false)}>
				<DialogTitle>Reset Password</DialogTitle>
				<DialogContent>
					<DialogContentText>Please enter your email:</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						id="email"
						label="Email Address"
						type="email"
						value={resetEmail}
						onChange={e => setEmail(e.target.value)}
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpen(false)}>Cancel</Button>
					<Button
						onClick={() => {
							dispatch(requestPasswordResetThunk({ email: resetEmail }));
							setTimeout(() => {
								setOpen(false);
							}, 1000);
						}}
					>
						Submit
					</Button>
				</DialogActions>
			</Dialog>

			<CssBaseline />
			<Box
				sx={{
					marginTop: 4,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<IconButton
					edge="start"
					onClick={() => navigate("/")}
					aria-label="close"
					sx={{ position: "absolute", right: 8, top: 8, color: "#ffffff" }}
				>
					<CloseIcon />
				</IconButton>
				<img src={logo} style={{ width: 200 }} alt="logo" />
				<Box component="form" onSubmit={HandleSubmit} sx={{ mt: 1 }}>
					<TextField
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						name="email"
						autoComplete="email"
						autoFocus
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="current-password"
					/>

					{error && (
						<p style={{ color: "red" }}>
							{error.message || "Something went wrong"}
						</p>
					)}
					{loading === "loaded" && <Navigate to="/" />}
					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{
							mt: 3,
							mb: 2,
							bgcolor: "#232323",
							":hover": { bgcolor: grey[700] },
						}}
					>
						{loading === "loading" ? <AppLoader /> : "Sign In"}
					</Button>
					<Typography>
						<p>
							Dont have an account?{" "}
							<Button onClick={HandleRegisterClick}>Register</Button>
						</p>
						<p>
							Forgot your password?
							<Button onClick={HandleClick}>Request password reset</Button>
						</p>
					</Typography>
				</Box>
			</Box>
		</Container>
	);
};
