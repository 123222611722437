import { createSlice } from "@reduxjs/toolkit";
import { logoutCurrentThunk } from "../../thunks/userThunks";

interface logoutCurrentState {
	loading: string;
	error: string | undefined;
}

const initialState: logoutCurrentState = {
	loading: "idle",
	error: undefined,
};

const logoutCurrentSlice = createSlice({
	name: "logoutCurrent",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(logoutCurrentThunk.fulfilled, state => {
			state.loading = "loaded";
		});
		builder.addCase(logoutCurrentThunk.pending, state => {
			state.loading = "loading";
		});
		builder.addCase(logoutCurrentThunk.rejected, (state, action) => {
			state.error = action.error.message;
			state.loading = "error";
		});
	},
});

export default logoutCurrentSlice.reducer;
