import { FC, useEffect } from "react";
import { Box } from "@mui/material";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { MainLayout } from "./MainLayout";
import AppLoader from "../Shared/AppLoader";
import { getAllUsersThunk } from "../../store/thunks/userThunks";
import { UsersTable } from "../UsersTable/UsersTable";
import { userColumns } from "../../constants/userTableColumns";

export const AdminPage: FC = () => {
	const { loading, error, allUsers } = useAppSelector(state => state.users);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getAllUsersThunk());
	}, []);

	return (
		<MainLayout>
			<Box
				sx={{
					display: "flex",
					justifyContent: "top",
					alignItems: "center",
					flexDirection: "column",
					minHeight: "100vh",
					backgroundColor: "#232323",
				}}
			>
				{loading === "loading" && <AppLoader />}
				{loading === "loaded" && allUsers && (
					<UsersTable columns={userColumns} users={allUsers.users} />
				)}
				{loading === "error" && (
					<div>{error?.message || "Something went wrong"}</div>
				)}
			</Box>
		</MainLayout>
	);
};
