import { FC } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
import { grey } from "@mui/material/colors";
import { RegisterUser } from "../../types/user";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import logo from "../../assets/images/logo-color.png";
import { registerUserThunk } from "../../store/thunks/userThunks";
import AppLoader from "../Shared/AppLoader";

export const RegisterForm: FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { loading, error } = useAppSelector(state => state.registerUser);

	const HandleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const registerData: RegisterUser = {
			username: data.get("username") as string,
			firstName: data.get("firstName") as string,
			lastName: data.get("lastName") as string,
			email: data.get("email") as string,
			password: data.get("password") as string,
		};
		dispatch(
			registerUserThunk({
				user: registerData,
				apikey: data.get("key") as string,
			})
		);
	};

	return (
		<Container
			component="main"
			maxWidth="xs"
			sx={{
				backgroundColor: "white",
				borderRadius: "15px",
				paddingY: "10px",
			}}
		>
			<CssBaseline />
			<Box
				sx={{
					marginTop: 4,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<IconButton
					edge="start"
					onClick={() => navigate("/")}
					aria-label="close"
					sx={{ position: "absolute", right: 8, top: 8, color: "#ffffff" }}
				>
					<CloseIcon />
				</IconButton>
				<img src={logo} style={{ width: 200 }} alt="logo" />
				<Box component="form" onSubmit={HandleSubmit} sx={{ mt: 1 }}>
					<TextField
						margin="normal"
						required
						fullWidth
						id="key"
						label="Key Sandy provided you with"
						name="key"
						autoComplete="apiKey"
						autoFocus
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						id="username"
						label="User Name"
						name="username"
						autoComplete="Username"
						autoFocus
					/>
					<TextField
						margin="normal"
						fullWidth
						id="firstName"
						label="First Name"
						name="firstName"
						autoComplete="First Name"
						autoFocus
					/>
					<TextField
						margin="normal"
						fullWidth
						id="lastName"
						label="Last Name"
						name="lastName"
						autoComplete="Last Name"
						autoFocus
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						name="email"
						autoComplete="email"
						autoFocus
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="current-password"
					/>

					{error && (
						<p style={{ color: "red" }}>
							{error.message || "Something went wrong"}
						</p>
					)}
					{loading === "loaded" && <Navigate to="/" />}
					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{
							mt: 3,
							mb: 2,
							bgcolor: "#232323",
							":hover": { bgcolor: grey[700] },
						}}
					>
						{loading === "loading" ? <AppLoader /> : "Register"}
					</Button>
				</Box>
			</Box>
		</Container>
	);
};
