import { Box, Typography, Button } from "@mui/material";
import { MainLayout } from "./MainLayout";

export const LandingPage = () => (
	<MainLayout>
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				minHeight: "100vh",
				color: "white",
				backgroundImage:
					"url(https://images.unsplash.com/photo-1573561507036-b0110570e2e3?q=80&w=1587&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
				backgroundSize: "cover",
			}}
		>
			<Typography variant="h2" textAlign="center" gutterBottom>
				Welcome to My Personal Website
			</Typography>
			<Typography variant="subtitle1" textAlign="center" marginBottom={4}>
				Why not have a look at some random names?
			</Typography>
			<Button
				variant="contained"
				onClick={() => (window.location.href = "#/dnd")}
				sx={{
					backgroundColor: "white",
					color: "#232323",
					"&:hover": {
						backgroundColor: "#232323",
						color: "white",
					},
				}}
				size="large"
			>
				Have a look
			</Button>
		</Box>
	</MainLayout>
);
