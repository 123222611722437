import { FC, useEffect, useState } from "react";
import {
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { useAppSelector } from "../../hooks/useAppSelector";
import { getAllNamesThunk } from "../../store/slices/getAllNamesSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { Name } from "../../types/names";

export const GetNames: FC = () => {
	const dispatch = useAppDispatch();
	const { loading, error, allNames } = useAppSelector(state => state.allNames);
	let amountOfNames = 0;
	let randomNumber = 0;

	const [displayedNames, setDisplayedNames] = useState<Name[]>([]);
	const [randomNames, setRandomNames] = useState<string[]>([]);

	useEffect(() => {
		if (loading === "loaded" && allNames) {
			amountOfNames = allNames?.names.length;
			randomNumber = getRandomNumber(0, amountOfNames - 6);
			const newDisplayedNames = allNames.names.slice(
				randomNumber,
				randomNumber + 6
			);
			setDisplayedNames(newDisplayedNames);
			const newRandomNames = newDisplayedNames.map(name => getRandomName());
			setRandomNames(newRandomNames);
		}
	}, [loading, allNames]);

	function getRandomNumber(min: number, max: number): number {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	useEffect(() => {
		dispatch(getAllNamesThunk());
	}, []);

	if (loading === "loaded" && allNames) {
		amountOfNames = allNames?.names.length;
		randomNumber = getRandomNumber(0, amountOfNames - 6);
	}

	const getRandomName = (): string => {
		const randomNamesList = allNames?.names;
		const randomIndex = getRandomNumber(0, randomNamesList.length - 1);
		return randomNamesList[randomIndex].name;
	};

	const handleNameClick = (index: number) => {
		const newRandomNames = [...randomNames];
		newRandomNames[index] = getRandomName();
		setRandomNames(newRandomNames);
	};

	return (
		<TableContainer
			component={Paper}
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				width: "40vw",
				margin: "0.5rem",
			}}
		>
			<Table>
				<TableBody>
					{loading === "loading" && (
						<TableRow>
							<TableCell>Loading... The api has to wake up</TableCell>
						</TableRow>
					)}
					{loading === "error" && (
						<TableRow>
							<TableCell>Error: {error?.message}</TableCell>
						</TableRow>
					)}
					{loading === "loaded" && allNames && (
						<TableRow>
							<TableCell>
								<Table>
									<TableBody>
										{displayedNames.map((name, index) => (
											<TableRow
												key={name.id}
												sx={{
													alignItems: "center",
													justifyContent: "center",
												}}
											>
												<TableCell
													sx={{
														border: 0,
														textAlign: "center",
													}}
												>
													<Typography
														component={Button}
														onClick={() => handleNameClick(index)}
														sx={{
															textTransform: "none",
															color: "black",
															justifyContent: "center",
														}}
													>
														{randomNames[index]}
													</Typography>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
