import type { FC } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga";
import { routeFactory } from "./routeFactory";
import { GitHubPage } from "../components/Pages/GitHubPage";
import { LandingPage } from "../components/Pages/LandingPage";
import { UnitConversions } from "../components/Pages/UnitConversions";
import { LoginPage } from "../components/Pages/LoginPage";
import { RegisterPage } from "../components/Pages/RegisterPage";
import { ProtectedRoute } from "../utils/ProtectedRoute";
import { RoleBasedRoute } from "../utils/RoleBasedRoute";
import { DndUtils } from "../components/Pages/DndUtils";
import { ResetPasswordPage } from "../components/Pages/ResetPasswordPage";
import { GamePage } from "../components/Pages/GamePage";
import { AdminPage } from "../components/Pages/AdminPage";

ReactGA.initialize("G-F7DNZKXWWS");

export const AppRoutes: FC = () => (
	<HashRouter basename="/">
		<Routes>
			<Route path={routeFactory.appRoot()} element={<LandingPage />} />
			<Route path={routeFactory.unit()} element={<UnitConversions />} />
			<Route path={routeFactory.github()} element={<GitHubPage />} />
			<Route path={routeFactory.dndutils()} element={<DndUtils />} />
			<Route
				path={routeFactory.profile()}
				element={
					<ProtectedRoute>
						<RoleBasedRoute roles={["Admin", "User"]}>
							<div>DndUtils</div>
						</RoleBasedRoute>
					</ProtectedRoute>
				}
			/>
			<Route
				path={routeFactory.admin()}
				element={
					<ProtectedRoute>
						<RoleBasedRoute roles={["Admin"]}>
							<AdminPage />
						</RoleBasedRoute>
					</ProtectedRoute>
				}
			/>
			<Route path={routeFactory.login()} element={<LoginPage />} />
			<Route path={routeFactory.register()} element={<RegisterPage />} />
			<Route path={routeFactory.game()} element={<GamePage />} />
			<Route
				path={routeFactory.resetPasswordByEmail({
					token: ":token",
				})}
				element={<ResetPasswordPage />}
			/>
			<Route path="*" element={<LandingPage />} />
		</Routes>
	</HashRouter>
);
