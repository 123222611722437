import { LogoutCurrentDto, RefreshAccessTokenDto, Token } from "../types/token";
import {
	ChangePassword,
	LoginUser,
	RegisterUser,
	RequestPasswordReset,
	ResetPasswordWithToken,
	UpdateUser,
} from "../types/user";
import { getTokenFromLocalStorage } from "./sessionStorageServices";

class UserApiService {
	private baseUrl = "https://sandyssuperapi.azurewebsites.net/users";

	public async loginUser(loginDto: LoginUser) {
		const response = await fetch(`${this.baseUrl}/login`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"x-api-key": "EDF0EF2F97DA4191A85335A0EDB4C107",
				accept: "application/json",
			},
			body: JSON.stringify(loginDto),
		}).catch(err => {
			throw new Error("Could not connect to server");
		});

		return response;
	}

	public async validateToken(token: Token) {
		const response = await fetch(`${this.baseUrl}/validate-Token`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"x-api-key": "EDF0EF2F97DA4191A85335A0EDB4C107",
			},
			body: JSON.stringify(token),
		}).catch(err => {
			throw new Error("Could not connect to server");
		});

		return response;
	}

	public async logoutCurrent(refreshToken: LogoutCurrentDto) {
		const response = await fetch(`${this.baseUrl}/logoutCurrent`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getTokenFromLocalStorage()}`,
			},
			body: JSON.stringify(refreshToken),
		}).catch(err => {
			throw new Error("Could not connect to server");
		});

		return response;
	}

	public async refreshToken(tokens: RefreshAccessTokenDto) {
		const response = await fetch(`${this.baseUrl}/refresh`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getTokenFromLocalStorage()}`,
			},
			body: JSON.stringify(tokens),
		}).catch(err => {
			throw new Error("Could not connect to server");
		});

		return response;
	}

	public async getAllUsers() {
		const response = await fetch(`${this.baseUrl}/getAll`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getTokenFromLocalStorage()}`,
			},
		}).catch(err => {
			throw new Error("Could not connect to server");
		});

		return response;
	}

	public async getUserById(id: string) {
		const response = await fetch(`${this.baseUrl}/getById/?id=${id}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getTokenFromLocalStorage()}`,
			},
		}).catch(err => {
			throw new Error("Could not connect to server");
		});

		return response;
	}

	public async updateUser(id: string, user: UpdateUser) {
		const response = await fetch(`${this.baseUrl}/update/?id=${id}`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getTokenFromLocalStorage()}`,
			},
			body: JSON.stringify(user),
		}).catch(err => {
			throw new Error("Could not connect to server");
		});

		return response;
	}

	public async deleteUser(id: string) {
		const response = await fetch(`${this.baseUrl}/delete/?id=${id}`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getTokenFromLocalStorage()}`,
			},
		}).catch(err => {
			throw new Error("Could not connect to server");
		});

		return response;
	}

	public async changePassword(changePasswordDto: ChangePassword) {
		const response = await fetch(`${this.baseUrl}/changePassword`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getTokenFromLocalStorage()}`,
			},
			body: JSON.stringify(changePasswordDto),
		}).catch(err => {
			throw new Error("Could not connect to server");
		});

		return response;
	}

	public async getCurrentUser() {
		const response = await fetch(`${this.baseUrl}/getCurrent`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getTokenFromLocalStorage()}`,
			},
		}).catch(err => {
			throw new Error("Could not connect to server");
		});

		return response;
	}

	public async setRoles(id: string, roles: string[]) {
		const response = await fetch(`${this.baseUrl}/setRoles/?id=${id}`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getTokenFromLocalStorage()}`,
			},
			body: JSON.stringify(roles),
		}).catch(err => {
			throw new Error("Could not connect to server");
		});

		return response;
	}

	public async registerUser(user: RegisterUser, apikey: string) {
		const response = await fetch(`${this.baseUrl}/register`, {
			method: "POST",
			headers: {
				"x-api-key": apikey,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(user),
		}).catch(err => {
			throw new Error("Could not connect to server");
		});

		return response;
	}

	public async requestPasswordReset(ResetRequestDto: RequestPasswordReset) {
		const response = await fetch(`${this.baseUrl}/request-reset-password`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(ResetRequestDto),
		}).catch(err => {
			throw new Error("Could not connect to server");
		});

		return response;
	}

	public async resetPasswordWithToken(dto: ResetPasswordWithToken) {
		const response = await fetch(`${this.baseUrl}/resetPasswordWithToken`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(dto),
		}).catch(err => {
			throw new Error("Could not connect to server");
		});

		return response;
	}
}

export const UserApi = new UserApiService();
